import * as React from "react"
import { graphql } from "gatsby"
import P5Component from "../components/p5-component"
import "../components/new_layout.css"
import Seo from "../components/seo"



const SoloWaveSketchName = ({ data }) => {
  
    return (
    <>
        <Seo title={`${data.specificWavesJson.name} Live Animation`} />
        <section className='live-animation'>
            <div className='container clearfix wave'>
                <P5Component attributes={data.specificWavesJson.attributes} />
            </div>
        </section>
    </>
    )
  }

export const query = graphql`
  query WaveLiveSketchQuery($id: String) {
    specificWavesJson(id: {eq: $id}) {
      id
      name
      description
      attributes {
        Number_of_colors
        alpha
        brightness
        hash_id
        hue
        jitter
        saturation
        speed
        stroke_thickness
        width
      }
      properties {
        mint_address
      }
    }
  }
`
  
  export default SoloWaveSketchName